import { Component, OnInit } from '@angular/core';
import {NavController} from "@ionic/angular";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
})
export class EmailFormComponent implements OnInit {

  public email = "";

  constructor(private navController : NavController, public httpClient: HttpClient) {

  }



  onChangeEvent(event: any){
    this.email = event.target.value;
  }

  ignor(){
    this.navController.navigateRoot('/next-level');
  }


  confirm(){
    this.httpClient.get('https://coulisses.feelity.fr/mailchimp.php?token=P4vGmAGHrYcnKUVakUZ944FvRBDPZXSFRs25qMQnmEdqnQaYHYmrmg&email='+this.email).subscribe((res)=>{
      this.navController.navigateRoot('/next-level');
    });

  }



  ngOnInit() {}

}
