import {Component, OnInit, ViewChild} from '@angular/core';

// import Swiper core and required modules
import SwiperCore, { Swiper, Virtual } from 'swiper';
import {SwiperComponent} from "swiper/angular";

// install Swiper modules
SwiperCore.use([Virtual]);

@Component({
  selector: 'app-new-email',
  templateUrl: './new-email.component.html',
  styleUrls: ['./new-email.component.scss'],
})
export class NewEmailComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent ;

  swiperIndex : number = 0;


  goTo(index){
    this.swiper.swiperRef.slideTo(index, 250);
    this.swiperIndex = index;
  }


  ngOnInit(): void {
  }

  async activeIndex($event){
    this.swiperIndex = $event[0].activeIndex;
  }


}
