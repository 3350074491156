import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() source : any = "";
  @Input() text : any = "";
  @Input() showAvatar : any = false;

  @Input() loading : boolean = true;

  constructor() { }

  ngOnInit() {
    setTimeout(()=>{
      this.loading = false;
    },2000);
  }

}
