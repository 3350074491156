import { NgModule } from '@angular/core';
import {SoundComponent} from "./sound/sound.component";
import {CommonModule} from "@angular/common";
import {IonicModule} from "@ionic/angular";
import {LottieAnimationViewModule} from "ng-lottie";
import {ShareComponent} from "./share/share.component";
import {ButtonComponent} from "./button/button.component";
import {NewMessageComponent} from "./new-message/new-message.component";
import {NewEmailComponent} from "./new-email/new-email.component";
import {SwiperModule} from "swiper/angular";
import {TextComponent} from "./text/text.component";
import {StartChapterComponent} from "./start-chapter/start-chapter.component";
import {JoinUsComponent} from "./join-us/join-us.component";
import {ChatMessageComponent} from "./chat-message/chat-message.component";
import {ButtonSecondaryComponent} from "./button-secondary/button-secondary.component";
import {ScoreComponent} from "./score/score.component";
import {InputComponent} from "./input/input.component";
import {BackComponent} from "./back/back.component";
import {AllNiveauComponent} from "./all-niveau/all-niveau.component";
import {EmailFormComponent} from "./email-form/email-form.component";
import {ResultComponent} from "./result/result.component";
import {ShareModalComponent} from "../modal/share-modal/share-modal.component";
import {PatienceModalComponent} from "../modal/patience-modal/patience-modal.component";


@NgModule({
    imports : [
        CommonModule,
        IonicModule.forRoot(),
      SwiperModule
    ],
    declarations: [
      SoundComponent,
      ShareComponent,
      ButtonComponent,
      NewMessageComponent,
      NewEmailComponent,
      TextComponent,
      StartChapterComponent,
      JoinUsComponent,
      ChatMessageComponent,
      ButtonSecondaryComponent,
      ScoreComponent,
      InputComponent,
      BackComponent,
      AllNiveauComponent,
      EmailFormComponent,
      ResultComponent,
      ShareModalComponent,
      PatienceModalComponent,
    ],
    exports: [
      SoundComponent,
      ShareComponent,
      ButtonComponent,
      NewMessageComponent,
      NewEmailComponent,
      TextComponent,
      StartChapterComponent,
      JoinUsComponent,
      ChatMessageComponent,
      ButtonSecondaryComponent,
      ScoreComponent,
      InputComponent,
      BackComponent,
      AllNiveauComponent,
      EmailFormComponent,
      ResultComponent,
      ShareModalComponent,
      PatienceModalComponent,
    ]
})
export class GlobalModule {}
